<template>
    <main class="main not-found" id="main">
        <div class="contility">
            <img v-bind:src="contility" alt="the contility" title="The contility" />
        </div>
        <div class="blue-bg not-found">
            <h1>404</h1>
            <h2>Error 404</h2>
            <p>Oops! We can’t seem to find the page you are looking for</p>
            <p>
                <a class="button" href="/">Back</a>
            </p>
        </div>
    </main>
</template>

<script>
export default {
	data() {
		return {
			contility: require("./../assets/img/contility.svg"),
		}
	},
}
</script>


<style lang="scss">
@import "./src/assets/scss/base/base.scss";
@import "./src/assets/scss/not-found.scss";
</style>
